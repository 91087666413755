import { useEffect, useState } from "react";
import API from "../Network/API";
import { useNavigate, useParams } from "react-router-dom";
import PostLoader from '../assets/loader.gif';

import { useSelector } from "react-redux";
import CardPayment from "../components/CardPayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Checkout() {

  const [clientSecret, setClientSecret] = useState("");
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const prams = useParams();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [fee, setFee] = useState(0);
  const event = JSON.parse(localStorage.getItem("event"));
  useEffect(() => {

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    const cart = JSON.parse(localStorage.getItem("data_checkout"));
    setData(cart)
    
    setTimezone(timezone);

    setLoading(true)
    const formData = new FormData()
    formData.append('amount', cart?.price)
    API.post(`/v1/oauth/create-payment-intent`, formData).then((response) => {
      setClientSecret(response.data.clientSecret)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    });


  }, [prams.slug]);




  const appearance = {
    theme: 'stripe',
    variables: {
      // colorPrimary: '#0570de',
      // colorBackground: '#ffffff',
      // colorText: '#303238',
      // colorDanger: '#df1b41',
      // fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
      // spacingUnit: '2px',
      // borderRadius: '4px',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {isLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <div className="payment-checkuot">
          <div className="container">
            <div className="row m-0">
              <div className="col-md-7 col-12">
                <div className="box-right ">
                  <div className="row">
                    <div className="col-12">
                      <div className="checkoutTeacherInfowapper">
                        <div className="checkoutTeacherInfo me-3">
                          
                          <div className="checkoutTeacherImageInner">
                            <img src={data?.teacher_image ? data.teacher_image : '/assets/img/user-default.png'} alt="" />
                          </div>
                          <div className="checkoutTeacherCountryImageInner">
                            <img src="assets/img/us.svg" alt="" />
                          </div>
                        </div>
                        <div className="checkoutTeacherContent">
                          <p className="checkoutCourseLanguage mb-0">{data?.course_title}</p>
                          <div className="checkoutTeacherNameArea">
                            <div className="checkoutTeacherNameTtop d-flex align-items-center mt-1 mb-1">
                              <h4 className="checkoutTeacherName me-3 fs-24-600 mb-0">{data?.teacher_name}</h4>
                              <div className="checkoutTeacherTotalRatingInner d-flex me-3 align-items-center">
                                <i className="fa-light fa-star icon-24" />
                                <p className="checkoutTeacherTotalRating ms-2 mb-0 fs-20-600">{data?.avg_rating}</p>
                              </div>
                              <p className="checkoutTeacherTotalReview mb-0 fs-16-400">({data?.total_review} reviews)</p>
                            </div>
                            <div className="checkoutTeacherVerifiedInner d-flex align-items-center">
                              <div className="checkoutTeacherVerified d-flex me-3 align-items-center">
                                <i className="fa-light fa-badge-check icon-24" />
                                <p className="checkoutTeacherTotalRating ms-1 mb-0">Verified</p>
                              </div>
                              <div className="checkoutTeacherProfession d-flex align-items-center">
                                <i className="fa-light fa-award icon-24" />
                                <p className="checkoutTeacherTotalRating ms-1 mb-0">Professional</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`row justify-content-center ${data?.course_type=='recorded'?'d-none':'d-block'}`}>
                    <div className="col-md-10 d-none">
                      <div className="checkoutHeading-payment-form-btn mt-4">
                        {/* Credit card form tabs */}
                        <ul role="tablist" className="nav bg-light nav-pills rounded nav-fill mb-3 checkoutPayment">
                          <li className="nav-item"> <a data-toggle="pill" href="#credit-card" className="nav-link active "> <span className="d-block fw-bold">25 mins</span> <span className="d-block fw-bold">BDT 1,208</span></a> </li>
                          <li className="nav-item"> <a data-toggle="pill" href="#paypal" className="nav-link "> <span className="d-block fw-bold">50 mins</span> <span className="d-block fw-bold">BDT 1,908</span></a></li>
                        </ul>
                      </div> {/* End */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="Divider-sc" />
                    </div>
                  </div>
                  <div className={`row ${data?.course_type=='recorded'?'d-none':'d-block'}`}>
                    <div className="col-12">
                      <div className="course-schedule">
                        <p className="course-schedule-time mb-0">{ moment.tz(event?.start, timezone).format('YYYY-MM-DD hh:mm A') } {timezone}</p>
                        <p className="course-schedule-location mb-0">Time is based on your location</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="Divider-sc" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="order-heading mb-3">
                        <h2>Your order</h2>
                      </div>
                      <div className="order-dec">
                        <div className="d-flex justify-content-between order-dec-item">
                          <p className="order-dec-item-name">Sub Total</p>
                          <p className="order-dec-item-price fw-bold">${data?.price}</p>
                        </div>
                      </div>
                      <div className="order-dec">
                        <div className="d-flex justify-content-between order-dec-item">
                          <p className="order-dec-item-name">Processing fee</p>
                          <p className="order-dec-item-price fw-bold">${fee}</p>
                        </div>
                      </div>
                      <div className="order-dec mb-3">
                        <div className="d-flex justify-content-between mb-2 mt-2 order-dec-total-item">
                          <p className="order-dec-item-total-name">Total</p>
                          <p className="order-dec-item-total-price">${(Number(data?.price)+Number(fee)).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <input className="form-control input-hight-44" type="text" placeholder="Enter your promo code" />
                    </div>
                    <div className="col-4">
                      <a href="#" className="btn rounded btn-light w-100 input-hight-44 pt-2">
                        Apply
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 ps-md-5 p-0 ">
                <div className="box-left">
                  <div className="checkoutHeading">
                    <h1>Choose how to pay</h1>
                  </div>
                  <div className="checkoutHeading-payment-form">
                    <div className="checkoutHeading-payment-form-btn">
                      {/* Credit card form tabs */}
                      <ul role="tablist" className="nav bg-light nav-pills rounded nav-fill mb-3 checkoutPayment">
                        <li className="nav-item"> <a data-toggle="pill" href="#credit-card" className="nav-link active "> <i className="fa-light fa-credit-card me-2" /> Credit/Debit Card </a> </li>
                        <li className="nav-item"> <a data-toggle="pill" href="#paypal" className="nav-link "> <i className="fa-brands fa-paypal me-2" /> Paypal </a> </li>
                      </ul>
                    </div> {/* End */}
                    {/* Credit card form content */}
                    <div className="tab-content">
                      {/* credit card info*/}
                      <div id="credit-card" className="tab-pane fade show active pt-3 credit-card">
                        <div className="form">
                        
                            {clientSecret && (
                              <Elements options={options} stripe={stripePromise}>
                                <CardPayment data={data} price={(Number(data?.price)+Number(fee)).toFixed(2)}/>
                              </Elements>
                            )}
                           
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}