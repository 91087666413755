import { useEffect, useState } from "react";

export default function CourseLesson(props) {
    const [select_lesson, setSelectedLesson] = useState(props?.trial_lesson);
    const handleSelect = (lesson) => {
        setSelectedLesson(lesson)
        
      };

      useEffect(() => {
        localStorage.setItem(
            'lesson',
            JSON.stringify({
              id: select_lesson?.id,
              title: select_lesson?.title,
              price: select_lesson.lesson_price,
            })
          )
      }, [select_lesson]);

    return (
        <>
          
            <div className="lesson">
                <div className="courseDetailsLessonsArea">
                    <ul className="list-group list-group-flush">
                        {props?.course?.sections?.length>0 && props?.course?.sections?.map((section,k) => (
                      <>
                       {section?.lessons?.length>0 && section?.lessons?.map((lesson,j) => (
                        <li onClick={()=>handleSelect(lesson)} key={j} className={`d-flex justify-content-between align-items-center lesson-item ${select_lesson?.id==lesson?.id?'lesson-actived':''}`}>
                            <div className="flex-grow-1">
                                <div className="lesson-title">{lesson.title}</div>
                                <span className="fst-italic">0 lessons completed</span>
                            </div>
                            <div className="flex-shrink-0 ms-1 text-end">
                            
                            <p style={{width:'fit-content'}} role="button" className={` ${lesson?.is_trial?'lesson-trial-price':'lesson-price'}`} aria-disabled="true">USD {lesson.lesson_price}</p>
                            </div>
                        </li>
                         ))}
                      </>
                        ))}
                        
                    </ul>
                </div>
            </div>
        </>
    )
}