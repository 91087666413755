import React from "react";
import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
    return (
        <div className="single-course mb-3">
            <div className="courses-image position-relative">
                {course.cover_video ?
                    <>
                        <iframe width="auto" height="198" src={course.cover_video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </>
                    :
                    <>
                        <div className="courseMainImg h-200" style={{ backgroundImage: `url(${course.cover_image == null ? '/assets/img/no_image.jpg' : course.cover_image})` }}></div>
                        {course.course_type!='recorded'?
                        <span className="single-course-level position-absolute">
                                    <a href="#" class="text-white"><i class="fa-solid fa-signal-stream me-1"></i>LIVE</a>
                                </span>
                                :''
                                }
                    </>
                }
            </div>
            <div className="courses-content">
                <div className="CourseAuthorInfo">
                    {course?.teacher?.name}
                    <p className="authorDeg m-0">Teacher</p>
                </div>
                <h3 className="title">
                    <Link to={`/course/${course.slug}`}>{course?.title?.substring(0, 30)}</Link>
                </h3>
                <p className="course-dec">{course.description}</p>
                {course?.avg_rating != '0.00' ?
                    <div className="course-rating d-flex align-items-center mb-2">
                        <div className="course-rating-icon me-2">
                            <i className={`fas fa-star ${course.avg_rating >= 0 && course.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${course.avg_rating >= 2 && course.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${course.avg_rating >= 3 && course.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${course.avg_rating >= 4 && course.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                            <i className={`fas fa-star ${course.avg_rating >= 5 && course.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                        </div>
                        <span className="course-rating-number">{course.avg_rating}</span>
                    </div>
                    :
                    <div className="course-rating d-flex align-items-center mb-2">
                    </div>
                }

                <p className="courseLessonsNumberCount m-0">{course.lessons_count} Lessons</p>
                <Link to={`/course/${course.slug}`} className="courseBuyBtn font-weight-bold">SEE DETAILS</Link>
            </div>
        </div>
    );
}

export default CourseCard;
