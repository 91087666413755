import { Link } from "react-router-dom";

export default function CourseTableRow({data}){
    return (
        <>
        <tr>
                            <td>
                              <div className="table-course-detail">
                                <a href="#" className="course-table-img">
                                  <span><img src={data?.course?.cover_image} alt={data?.course?.title}/></span>
                                  {data?.course?.title}
                                </a>
                              </div>
                            </td>
                            <td>{data?.lesson?.title}</td>
                            <td>{data?.start_time}</td>
                            <td>{data?.end_time}</td>
                            <td>{data?.timezone}</td>
                            <td><Link to={`/live-class/${data?.course?.slug}/${data?.room_no}`} className="btn btn-sm btn-success">Start</Link></td>
                          </tr>
        </>
    )
}