import { useSelector } from "react-redux";
import TutorNav from "./TutorNav";
import CourseTableRow from "../../Props/CourseTableRow";

export default function TutorHome(){
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const select_course = useSelector((state) => state.myCourseReducer);
    const select_upcoming_course = useSelector((state) => state.upcomingEnrollmentReducer);
    const totalEnrollment = select_course?.courses?.reduce((sum, course) => sum + course.total_enrollment, 0)??0;
    return (
        <>
           <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                      <div className="icon d-flex justify-content-center align-items-center me-3">
                        <i className="fa-light fa-users" />
                      </div>
                      <div className="content">
                        <span className="count">{totalEnrollment??0}</span>
                        <p className="mb-0">Total Students</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                      <div className="icon d-flex justify-content-center align-items-center me-3">
                        <i className="fa-light fa-book" />
                      </div>
                      <div className="content">
                        <span className="count">{select_course?.data?.total??0}</span>
                        <p className="mb-0">Total Courses</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="shadow-sm dashboard__counter-item d-flex align-items-center text-start overflow-hidden position-relative">
                      <div className="icon d-flex justify-content-center align-items-center me-3">
                        <i className="fa-light fa-money-bill" />
                      </div>
                      <div className="content">
                        <span className="count">0</span>
                        <p className="mb-0">Total Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-title">
                  <h4>Upcoming Courses</h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive custom-table">
                      <table className="table table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Lesson</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Timezone</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {!select_upcoming_course?.loading && select_upcoming_course?.courses?.length > 0 && select_upcoming_course?.courses?.map((course, j) => (
                         <CourseTableRow data={course} key={j}/>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
        </>
    )
}