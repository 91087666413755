import { useState } from "react";
import { useSelector } from "react-redux";

export default function TeacherFilter({handleCategory,handleSpeak,handleCountry}){
  const select_language = useSelector((state) => state.languageReducer);
  const select_country = useSelector((state) => state.countryReducer);
  const categories = useSelector((state) => state.categoryReducer);


    const categoryFun=(cid)=>{
      handleCategory(cid)
    }
    const speakFun=(cid)=>{
      handleSpeak(cid)
    }
    const countryFun=(conId)=>{
      handleCountry(conId)
    }

    return(
        <>
        <div className="show-filter add-course-info">
                <form action="#">
                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                    
                  <div className="dropdown dropdown-large">
        <a className="btn btn-white btn-dropdown-white rounded-pill dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-regular fa-tag me-1" /> Category
        </a>
        <div className="dropdown-menu dropdown-menu-large" style={{}}>
          <div className="dropdown-menu-large-area">
            <div className="row">
              <div className="col-12">
                <div className="TeacherSearch_teacherFilterBar">
                  <h4 className="heading">Essentials</h4>
                  <div className="mt-3">

                    <button type="button" className="btn btn-light mb-2 btn-sm rounded-pill">All</button>
                    {categories?.data?.length > 0 && categories?.data?.map((item, i) => (
                    <button onClick={()=>categoryFun(item.id)} type="button" className="btn btn-light mb-2 btn-sm rounded-pill">{item.name}</button>
                 
                 ))}

                  </div>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
                    <div className="dropdown dropdown-large">
                      <a className="btn btn-white btn-dropdown-white rounded-pill dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-comment-alt-lines me-1" /> Speaks
                      </a>
                      <div className="dropdown-menu dropdown-menu-large ">
                        <div className="dropdown-menu-large-area">
                          <div className="row">
                            <div className="col-12">
                              <div className="TeacherSearch_teacherFilterBar">
                                <div className=" search-group">
                                  <i className="fa-light fa-search" />
                                  <input type="text" className="form-control" placeholder="Search speaks" />
                                </div>
                              </div>
                              <div className="TeacherSearch_teacherFilterBar">
                                <h4 className="heading">Essentials</h4>
                                <div className="mt-3">
                                  <button type="button" className="btn btn-light mb-2 btn-sm rounded-pill">All</button>
                                  {!select_language?.loading && select_language?.data?.map(language => (
                                  <button onClick={()=>speakFun(language.id)} type="button" className="btn btn-light mb-2 btn-sm rounded-pill">{language.name}</button>
                                ))}
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown dropdown-large">
                      <a className="btn btn-white btn-dropdown-white rounded-pill dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-location-dot me-1" /> Teacher From
                      </a>
                      <div className="dropdown-menu dropdown-menu-large ">
                        <div className="dropdown-menu-large-area">
                          <div className="row">
                            <div className="col-12">
                              <div className="TeacherSearch_teacherFilterBar">
                                <div className=" search-group">
                                  <i className="fa-light fa-search" />
                                  <input type="text" className="form-control" placeholder="Search country" />
                                </div>
                              </div>
                              <div className="TeacherSearch_teacherFilterBar">
                                <h4 className="heading">Language essentials</h4>
                                <div className="mt-3">
                                  <button type="button" className="btn btn-light mb-2 btn-sm rounded-pill">All</button>
                                  {!select_country?.loading && select_country?.data?.map(country => (
                                  <button onClick={()=>countryFun(country.code)} type="button" className="btn btn-light mb-2 btn-sm rounded-pill">{country.name}</button>
                                ))}
                                </div>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </form>
              </div>
        </>
    )
}