import { useDispatch, useSelector } from "react-redux";
import { USER_LOGGED_IN } from "./Reducer/authSlice";
import { BrowserRouter, Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./Pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./Auth/Login";
import SignUp from "./Auth/Signup";
import SignupStudent from "./Auth/SignupStudent";
import TutorDashboard from "./Pages/Tutor/TutorDashboard";
import StudentDashboard from "./Pages/Student/StudentDashboard";
import AllCourse from "./Pages/AllCourse";
import CourseDetail from "./Pages/CourseDetail";
import BecomeTeach from "./Pages/BecomeTeach";
import TutorHome from "./Pages/Tutor/TutorHome";
import StudentHome from "./Pages/Student/StudentHome";
import SinglePage from "./Pages/SinglePage";
import CategoryCourse from "./Pages/CategoryCourse";
import Checkout from "./Pages/Checkout";
import OrderComplete from "./Pages/OrderComplete";
import MyLesson from "./Pages/Student/MyLesson";
import LessonView from "./Pages/Student/LessonView";
import TutorCourse from "./Pages/Tutor/Course/TutorCourse";
import AddCourse from "./Pages/Tutor/Course/AddCourse";
import EditCourse from "./Pages/Tutor/Course/EditCourse";
import CourseSection from "./Pages/Tutor/Section/CourseSection";
import RoomView from "./Pages/RoomView";
import FavCourse from "./Pages/Student/FavCourse";
import StudentChat from "./Pages/Student/StudentChat";
import ChatBody from "./Props/ChatBody";
import BlankInbox from "./Props/BlankInbox";
import TeacherChat from "./Pages/Tutor/TeacherChat";
import TeacherList from "./Pages/TeacherList";
import TeacherView from "./Pages/TeacherView";

function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup-student" element={<SignupStudent />} />
        <Route path="/signup-tutor" element={<SignUp />} />
        <Route path="/all-courses" element={<AllCourse />} />
        <Route path="/course/:slug" element={<CourseDetail />} />
        <Route path="/page/:slug" element={<SinglePage />} />
        <Route path="/category/:slug" element={<CategoryCourse />} />
        <Route path="/teach" element={<BecomeTeach />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/order/process" element={<OrderComplete />} />
        <Route path="/teachers" element={<TeacherList />} />
        <Route path="/teacher/:id" element={<TeacherView />} />

        <Route path="/dashboard" element={<TutorDashboard />}>
          <Route path="tutor" element={<TutorHome />} />
          <Route path="my-courses" element={<TutorCourse />} />
          <Route path="my-course/create" element={<AddCourse />} />
          <Route path="my-course/edit/:id" element={<EditCourse />} />
          <Route path="my-course/sections/:id" element={<CourseSection />} />

          <Route path="chat" element={<TeacherChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

        </Route>

        <Route path="/home" element={<StudentDashboard />} >
          <Route path="student" element={<StudentHome />} />
          <Route path="my-lessons" element={<MyLesson />} />
          <Route path="save-course" element={<FavCourse />} />
          <Route path="chat" element={<StudentChat />}>
            <Route index element={<BlankInbox />} />
            <Route path="conversation/:id" element={<ChatBody />} />
          </Route>

        </Route>
        <Route path="lesson/:course_slug/:lesson_id" element={<LessonView />} />
        <Route path="room/:course_slug/:room_id" element={<RoomView />} />

      </Routes>
      <Footer />
    </>
  );
}

const PrivateRoute = ({ url }) => {

  const selector_permission = useSelector((state) => state.permissionReducer)
  const auth = selector_permission?.main_data?.includes(url) ? true : false; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/" />;
}
export default App;
