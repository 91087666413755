import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CourseLesson from '../Props/CourseLesson';
import ScheduleSlot from '../components/ScheduleSlot';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function LessonModal({ show, onHide, course,lesson }) {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const inpRef = useRef()
    const modalRef1 = useRef()
    const [step, setStep] = useState(0);
    const [error_message, setErrorMessage] = useState('');
    const navigate = useNavigate()
    const checkout = () => {
        const lesson = JSON.parse(localStorage.getItem("lesson"));
        const event = JSON.parse(localStorage.getItem("event"));
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if(event?.start==undefined || event?.start==''){
            setErrorMessage('Please select a schedule');
            return;
        }
        setErrorMessage('');
        localStorage.setItem(
          'data_checkout',
          JSON.stringify({
            course_id: course?.id,
            course_slug: course?.slug,
            course_title: course?.title,
            course_type: course?.course_type,
            lesson_id: lesson?.id,
            price: lesson?.price,
            total_review: course?.ratings_count,
            avg_rating: course?.avg_rating,
            teacher_name: course?.teacher?.name,
            teacher_id: course?.teacher?.id,
            teacher_image: course?.teacher?.avatar,
            timezone: timezone,
          })
        )


    
        if (!accessToken) {
          navigate('/login')
        } else {
          navigate('/checkout')
        }
    
      }

    return (
        <>
            <Modal show={show} onHide={onHide} keyboard="false"
                backdrop="static" className='modal-xl'>
                <Modal.Header>
                    <h5 class="modal-title">{step==0?'Select your lesson':'Schedule your lesson'}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>
                </Modal.Header>
                <Modal.Body style={{height:'500px',overflow:'auto'}}>
                    <div className="row">
                        <div className={`col-md-8 offset-md-2 ${step == 0 ? 'd-block' : 'd-none'}`}>
                            <CourseLesson trial_lesson={lesson} course={course}/>
                        </div>
                        <div className={`col-md-12 ${step == 1 ? 'd-block' : 'd-none'}`}>
                            <ScheduleSlot schedule={course?.teacher_schedules} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setStep(step - 1)} type="button" className="btn btn-secondary me-2">Prev</button>
                    {step<=0?
                    <button onClick={() => setStep(step + 1)} type="button" className="btn btn-secondary">Next</button>
                    :
                    <button onClick={() => checkout()} type="button" className="btn btn-secondary">Next</button>
                }
                    
                </Modal.Footer>
            </Modal>
        </>
    )
}