import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Network/API";
import toast from "react-hot-toast";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import moment from 'moment-timezone';
import { default as Select } from "react-select";
export default function TeacherInfo() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const [countries, setCountry] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null);
    const [bio, setBio] = useState(null);
    const [input, setInput] = useState({});
    const [timezones, setTimezones] = useState([]);
    const [country_id, setCountryId] = useState(0);
    const [time_zone, setTimeZoneId] = useState(null);

    const [schedules, setSchedules] = useState({
        Monday: { from_time: '', to_time: '' },
        Tuesday: { from_time: '', to_time: '' },
        Wednesday: { from_time: '', to_time: '' },
        Thursday: { from_time: '', to_time: '' },
        Friday: { from_time: '', to_time: '' },
        Saturday: { from_time: '', to_time: '' },
        Sunday: { from_time: '', to_time: '' }
    });

    const handleChange1 = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setInput({ ...input, [name]: value });
    };

    const handleInputChange = (day, field, value) => {
        setSchedules(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [field]: value
            }
        }));
    };



    useEffect(() => {
        const tzNames = moment.tz.names();
        const newOptions = tzNames.map(tz => ({
            value: tz, // Assuming you want the ID as a string
            label: tz
        }));
        setTimezones(newOptions);

        get_country();
    }, []);

    const get_country = () => {
        API.get(`/v1/oauth/countries`).then((response) => {
            // setCountry(response.data.data.countries);
            const newOptions = response.data.data.countries.map(item => ({
                value: item.code, // Assuming you want the ID as a string
                label: item.name
            }));
            setCountry(newOptions);
            setLoading(false);
        }).catch((error) => {
            setLoading(true);
        });
    }

    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    }

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    }

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };

    
    const onChangeCountry = (e) => {
        
          setCountryId(e?.value!=undefined?e?.value:'')
    };

    const onChangeTimeZone = (e) => {
        
          setTimeZoneId(e?.value!=null?e?.value:'')
    };

    const handleSubmit = (event) => {
        event.preventDefault();


        const fd = new FormData();

        fd.append('bio', input?.bio ?? '')
        fd.append('country', country_id ?? '')
        fd.append('phone', input?.phone ?? '')

        if (file) {
            fd.append('image', file, file?.name)
        }

        if (input?.age_verify) {
            fd.append('age_verify', 1)
        } else {
            fd.append('age_verify', 0)
        }

        if (input?.check_eduction) {
            fd.append('check_eduction', 1)
        } else {
            fd.append('check_eduction', 0)
        }

        fd.append('education_institute', input?.education_institute)
        fd.append('education_level', input?.education_level)
        fd.append('education_title', input?.education_title)
        fd.append('hourly_rate', input?.hourly_rate)
        fd.append('bank_name', input?.bank_name)
        fd.append('account_number', input?.account_number)
        fd.append('bank_additional', input?.bank_additional)
        fd.append('timezone', time_zone)

        fd.append('schedules', JSON.stringify(schedules))

        API.post("/v1/oauth/settings/tutor_profile", fd, {
            onUploadProgress: progressEvent => { }
        }).then((response) => {
            localStorage.setItem(
                'xller_auth',
                JSON.stringify({
                    user: response?.data?.data?.user,
                    accessToken: localStorage.getItem('xller_token'),
                })
            )

            const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
            if (userInfo) {
                dispatch(USER_LOGGED_IN(userInfo));
            }

            toast.success(response.data.message);
        }).catch((err) => {
            toast.error(err.response.data.message);
        });

    }
    return (
        <>
            <div className="tutor-sign-in-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-0">
                            <form id="msform" onSubmit={handleSubmit}>
                                {/* progressbar */}
                                <ul id="progressbar">
                                    <li className={step >= 1 ? "active" : ""} id="about">About</li>
                                    <li className={step >= 2 ? "active" : ""} id="photos">Photos</li>
                                    <li className={step >= 3 ? "active" : ""} id="education">Education</li>
                                    <li className={step >= 4 ? "active" : ""} id="availability">Availability</li>
                                    <li className={step >= 5 ? "active" : ""} id="availability">Bank Info</li>
                                    <li className={step >= 6 ? "active" : ""} id="finish">Finish</li>
                                </ul>
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col-lg-6 col-sm-10 col-12">
                                        {step === 1 && (
                                            <fieldset className={`text-end about ${step === 1 ? 'd-block' : 'd-done'}`}>
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>About</h2>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Start creating your public tutor profile. Your progress will be automatically saved as you complete each section. You can return at any time to finish your registration.</p>
                                                        </div>
                                                        <div className="step-form">
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="First_name" className="form-label">Your name</label>
                                                                    <input onChange={(e) => handleChange1(e)} defaultValue={user?.name} type="text" className="form-control rounded input-hight-44" id="First_name" placeholder="Your full name" name="name" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label className="form-label">Bio</label>
                                                                    <input onChange={(e) => handleChange1(e)} type="text" id="bio" name="bio" className="form-control input-hight-44" placeholder="write bio,ex:Speaks English (Native)" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label className="form-label">Country</label>
                                                                    <Select onChange={(e) => onChangeCountry(e)} options={countries} id="country" name="country" isClearable={true} />

                                                               
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label className="form-label">Phone number (optional)</label>
                                                                    <input onChange={(e) => handleChange1(e)} type="text" name="phone" className="form-control input-hight-44 fc-phone" aria-label="" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-12">
                                                                    <div className="form-check custom-form-check mb-3">
                                                                        <input onChange={(e) => handleChange1(e)} name="age_verify" className="form-check-input custom-form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                                                        <label className="form-check-label custom-form-check-label" htmlFor="flexCheckDefault">
                                                                            I confirm I’m over 18
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={nextStep} className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                            </fieldset>
                                        )}

                                        {step === 2 && (
                                            <fieldset className="text-end photos">
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>Profile photo</h2>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Choose a photo that will help learners get to know you.</p>
                                                        </div>
                                                        <div className="step-form">
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <div className="photos-upload-inner position-relative" id="cvpotos">
                                                                                <input className="photos-upload-input" type="file" onChange={(e) => setSelectedFile(e)} />
                                                                                <div className="photos-upload-text">
                                                                                    <p className="m-0">JPG or PNG, max 5MB</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <div className="step-upload-photos-name d-flex mb-2 align-items-center">
                                                                                <h4 className="me-2 fs-24-600 m-0">{user?.name}</h4>
                                                                            </div>
                                                                            <div className="step-upload-photos-text d-flex mb-2 d-none">
                                                                                <i className="fa-light fa-graduation-cap me-2" />
                                                                                <p className="m-0">Teaches English lessons</p>
                                                                            </div>
                                                                            <div className={`step-upload-photos-text d-flex ${bio == '' ? 'd-none' : ''}`}>
                                                                                <i className="fa-light fa-quote-left me-2" />
                                                                                <p className="m-0">{bio}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={prevStep} className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                                <button type="button" onClick={nextStep} className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                            </fieldset>
                                        )}

                                        {step === 3 && (
                                            <fieldset className="text-end education">
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>Education</h2>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Tell students more about the higher education that you've completed or are working on.</p>
                                                        </div>
                                                        <div className="step-form">
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <div className="form-check custom-form-check mb-3">
                                                                        <input onChange={(e) => handleChange1(e)} name="check_eduction" className="form-check-input custom-form-check-input" type="checkbox" defaultValue id="flexCheckDefault1" />
                                                                        <label className="form-check-label custom-form-check-label" htmlFor="flexCheckDefault1">
                                                                            I don't have a higher education degree
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="education_institue" className="form-label">University</label>
                                                                    <input onChange={(e) => handleChange1(e)} name="education_institute" type="text" className="form-control rounded input-hight-44" id="education_institue" placeholder="E.g. Mount Royal University" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="education_level" className="form-label">Degree</label>
                                                                    <input onChange={(e) => handleChange1(e)} name="education_level" type="text" className="form-control rounded input-hight-44" id="education_level" placeholder="E.g. Bachelor's degree in the English Language" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="education_title" className="form-label">Education Title</label>
                                                                    <input onChange={(e) => handleChange1(e)} name="education_title" type="text" className="form-control rounded input-hight-44" id="education_title" placeholder="English Native" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={prevStep} className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                                <button type="button" onClick={nextStep} className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                            </fieldset>
                                        )}

                                        {step === 4 && (
                                            <fieldset className="text-end availability">
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>Availability</h2>
                                                        </div>
                                                        <div className="step-form mb-5">
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label className="form-label ">Choose your timezone </label>
                                                                    <Select onChange={(e)=>onChangeTimeZone(e)} options={timezones} id="timezone" name="timezone" isClearable={true} />

                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Let students know when you're available for lessons.</p>
                                                        </div>

                                                        <div className="step-form">
                                                            {Object.keys(schedules).map(day => (
                                                                <div key={day}>
                                                                    <div className="form-group row">
                                                                        <div className="col-12">
                                                                            <div className="form-check custom-form-check mb-3">
                                                                                <input
                                                                                    className="form-check-input custom-form-check-input"
                                                                                    type="checkbox"
                                                                                    value={day}
                                                                                    id={`flexCheckDefault-${day}`}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label custom-form-check-label"
                                                                                    htmlFor={`flexCheckDefault-${day}`}
                                                                                >
                                                                                    {day}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group mb-3 row">
                                                                        <div className="col-6">
                                                                            <label className="form-label">From</label>
                                                                            <input
                                                                                type="time"
                                                                                className="form-control rounded input-hight-44"
                                                                                value={schedules[day].from_time}
                                                                                onChange={(e) => handleInputChange(day, 'from_time', e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="form-label">To</label>
                                                                            <input
                                                                                type="time"
                                                                                className="form-control rounded input-hight-44"
                                                                                value={schedules[day].to_time}
                                                                                onChange={(e) => handleInputChange(day, 'to_time', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={prevStep} className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                                <button type="button" onClick={nextStep} className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                            </fieldset>
                                        )}

                                        {step === 5 && (
                                            <fieldset className="text-end">
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>Bank Info</h2>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Add your hourly rate & bank information</p>
                                                        </div>
                                                        <div className="step-form">

                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="bank_name" className="form-label">Bank Name</label>
                                                                    <input name="bank_name" onChange={(e) => handleChange1(e)} type="text" className="form-control rounded input-hight-44" id="bank_name" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="account_number" className="form-label">Account Number</label>
                                                                    <input name="account_number" onChange={(e) => handleChange1(e)} type="text" className="form-control rounded input-hight-44" id="account_number" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="bank_additional" className="form-label">Other(optional)</label>
                                                                    <input name="bank_additional" onChange={(e) => handleChange1(e)} type="text" className="form-control rounded input-hight-44" id="bank_additional" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <label htmlFor="rate" className="form-label">Hourly Rate</label>
                                                                    <input name="hourly_rate" onChange={(e) => handleChange1(e)} type="number" min={0} className="form-control rounded input-hight-44" id="rate" placeholder="0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={prevStep} className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                                <button type="button" onClick={nextStep} className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Next</button>
                                            </fieldset>
                                        )}

                                        {step === 6 && (
                                            <fieldset className="text-end finish">
                                                <div className="form-card card mb-4">
                                                    <div className="card-body">
                                                        <div className="step-heading">
                                                            <h2>Finish</h2>
                                                        </div>
                                                        <div className="step-dec">
                                                            <p className="m-0">Review your information and complete your profile.</p>
                                                        </div>
                                                        <div className="step-form">
                                                            <div className="form-group mb-3 row">
                                                                <div className="col-12">
                                                                    <p>Your name: {user?.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={prevStep} className="next action-button input-hight-44 btn btn-secondary box-shadow-1 fw-bold me-1">Back</button>
                                                <button type="submit" className="next action-button input-hight-44 btn btn-login box-shadow-1 fw-bold">Submit</button>
                                            </fieldset>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
