import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import API from "../Network/API";
import ContentLoader, { Facebook, Code } from 'react-content-loader';
import CoursePlaceholder from "../CustomHooks/CoursePlaceholder";
import CourseCard from "../components/CourseCard";
export default function CategoryCourse() {
  const prams = useParams();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]);
  useEffect(() => {
    setLoading(true)
    API.get(`/v1/oauth/category_course/${prams.slug}`).then((response) => {
      setLoading(false)
      setData(response.data.data)
      setCourses(response.data.data.courses);
    }).catch((error) => {
      setLoading(false)
    });
  }, [prams.slug]);
  return (
    <>
      <div className="breadcrumb-bar breadcrumb-bar-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <h2 className="breadcrumb-title">{data?.category?.name}</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{data?.category?.name}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-are course-padding course-page">
        <div className="container">


          <div className="courses-wrapper">

            <div className="courses-class-content">
              <div className="row">
                {isLoading && courses?.map(course => (
                  <CoursePlaceholder />
                ))}
                {!isLoading && courses?.length > 0 && courses?.map((course, j) => (
                  <div className="col-lg-3 col-sm-6" key={j}>

                    <CourseCard course={course} />

                  </div>
                ))}
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}