import { Link } from "react-router-dom";

export default function TeacherProps(props){
    return (
        <>
        <div className="MainInstructorList flex-fill shadow-sm">
                        <div className="MainInstructorImg">
                          <div className="checkoutTeacherInfo">
                            <div className="checkoutTeacherImageInner">
                              <img src={props?.data?.avatar} alt={props?.data?.name}/>
                            </div>
                            <div className="instructorCountryFlag">
                              <img src="assets/img/us.svg" alt="" />
                            </div>
                          </div>
                          <div className="MainInstructorRating mt-3 mb-1">
                            <i className="fa-regular fa-star me-1" /><span className="me-1">{props?.data?.ratings_avg_rating}</span>(0)
                          </div>
                          <p className="TotalLessonCount mb-0">{props?.data?.lessons_count} Lessons</p>
                        </div>
                        <div className="MainInstructorContent ">
                          <div className="MainContentInstructorInfo justify-content-between d-flex">
                            <div className="InstructorInfoName mb-2">
                              <h5 className="InstructorInfoNameTitle mb-0">
                                <a href="#">{props?.data?.name}</a>
                                <i className="ms-1 fa-regular fa-shield-check" title="verified" />
                              </h5>
                              <h6 className="InstructorInfoType mb-0">Professional Teacher</h6>
                            </div>
                            <div className="InstructorInfoIcon">
                              <a href="#" className="btn btn-light rounded-pill btn-sm"><i className="fa-regular fa-heart" /></a>
                            </div>
                          </div>
                          <div className="instructor-badge mb-2">
                          Speak:
                          {props?.data?.languages?.length > 0 && props?.data?.languages?.map((lang, i) => (
                          <>
                             <span className="ms-1">{lang.name} </span><span className="badge bg-success text-uppercase">{lang.type}</span>
                          </>
                          ))}
                      
                          </div>
                          <div className="MainInstructorContentDec">
                            <p>
                            {props?.data?.teacher_info?.bio}
                            </p>
                          </div>
                          <div className="MainInstructorContentPrice">
                            <div className="d-flex justify-content-between InstructorContentPriceItem align-items-center">
                              <div className="InstructorContentPrice d-flex align-items-center">
                                <h3 className="price me-3 mb-0">USD {props?.data?.lesson_price} <span className="trial">/ trial</span></h3>
                                <span className="AvailableTime text-success d-none">Available 12:00</span>
                              </div>
                              
                              <Link to={`/teacher/${props?.data?.id}`} className="text-nowrap btn btn-light rounded-pill">View</Link>
                              
                            </div>
                          </div>
                        </div>
                      </div>
        </>
    )
}