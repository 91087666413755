import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { USER_LOGGED_IN } from "../Reducer/authSlice";
import API from "../Network/API";
import { MENU_FETCH_SUCCESS } from "../Reducer/menuSlice";
import { CATEGORY_FETCH_SUCCESS } from "../Reducer/categorySlice";
import { HOME_FETCH_COURSE_SUCCESS, HOME_FETCH_START } from "../Reducer/homeSlice";
import { COURSE_FETCH_START, COURSE_FETCH_SUCCESS, COURSE_TOTAL_PAGE } from "../Reducer/courseSlice";
import { APPEARANCE_FETCH_ERROR, APPEARANCE_FETCH_SUCCESS } from "../Reducer/appearanceSlice";
import { TEACHER_FETCH_START, TEACHER_FETCH_SUCCESS, TEACHER_TOTAL_PAGE } from "../Reducer/teacherSlice";
import { COUNTRY_FETCH_SUCCESS } from "../Reducer/countrySlice";
import { LANGUAGE_FETCH_SUCCESS } from "../Reducer/languageSlice";

export default function Header() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { user, accessToken } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
    if (userInfo) {
      dispatch(USER_LOGGED_IN(userInfo));
    }

  }, [dispatch]);

  useEffect(() => {
    
    API.get(`/menus`).then((response) => {
      dispatch(MENU_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });

    API.get('/appearance').then((response) => {
      dispatch(APPEARANCE_FETCH_SUCCESS(response.data.data));
    }).catch((error) => {
      dispatch(APPEARANCE_FETCH_ERROR());
    });

    API.get(`/v1/oauth/categories`).then((response) => {
      dispatch(CATEGORY_FETCH_SUCCESS(response.data.data.categories))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/countries`).then((response) => {
      dispatch(COUNTRY_FETCH_SUCCESS(response.data.data.countries))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/language`).then((response) => {
      dispatch(LANGUAGE_FETCH_SUCCESS(response.data.data.languages))
    }).catch((error) => {
    });


    API.get(`/v1/oauth/check_user`).then((response) => {
      
      localStorage.setItem(
        'xller_auth',
        JSON.stringify({
            user: response?.data?.user,
            accessToken: localStorage.getItem('xller_token'),
        })
    )

    const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
    if (userInfo) {
        dispatch(USER_LOGGED_IN(userInfo));
    }
    
    }).catch((error) => {
      // console.log(error?.response?.status);
      if(error?.response?.status==401 && error?.response?.statusText=="Unauthorized"){
        // navigator('/login')
        // localStorage.clear();
      }
    });

    dispatch(HOME_FETCH_START())
    dispatch(COURSE_FETCH_START())
    dispatch(TEACHER_FETCH_START())

    API.get(`/v1/oauth/all_course`).then((response) => {
      dispatch(COURSE_FETCH_SUCCESS(response.data.data))
      dispatch(COURSE_TOTAL_PAGE(response.data.data.total_page))
      dispatch((HOME_FETCH_COURSE_SUCCESS(response.data.data.courses)))
    }).catch((error) => {
    });

    API.get(`/v1/oauth/all_teachers`).then((response) => {
      dispatch(TEACHER_FETCH_SUCCESS(response.data.data))
      dispatch(TEACHER_TOTAL_PAGE(response.data.data.total_page))
    }).catch((error) => {
    });





  }, []);


  return (
    <>
      <header className="mainHarder">
        <nav className="navbar navbar-expand-lg  mainHarderNavbar">
          <div className="container">
            <Link className="navbar-brand mainHarderLogo" to="/">
              <img src="/assets/img/logo.svg" alt="xller" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse mainHarderMenu" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    English/USD
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">English/USD</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/teachers`}>Find Tutors</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/all-courses`}>All Courses</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/teach`}>Become a Tutors</Link>
                </li>

                {!accessToken ?
                  <li className="nav-item">
                    <Link className="nav-link" to={`/login`}>Login</Link>
                  </li>
                  :
                  <>
                    {accessToken && user.role == 'teacher' ?
                      <li className="nav-item">
                        <Link className="nav-link" to={`/dashboard/tutor`}>Dashboard</Link>
                      </li>
                      :
                      <li className="nav-item">
                        <Link className="nav-link" to={`/home/student`}>Dashboard</Link>
                      </li>

                    }
                  </>
                }

                {!accessToken ?
                  <li className="nav-item">
                    <Link className=" btn btn-outline-mainColor" to={`/signup-student`}>Sign up</Link>
                  </li>
                  : ''
                }
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div className="mobile-menu">
        <nav className="navbar fixed-top bg-white">
          <div className="container">
            <a className="navbar-brand mainHarderLogo" href="#">
              <img src="assets/img/logo.svg" alt="" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div className="offcanvas-header">
                <Link className="navbar-brand mobile-offcanvs-logo" to={`/`}>
                  <img src="/assets/img/logo.svg" alt="xller" />
                </Link>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 px-3 mobile-offcanvs-menu">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      English/USD
                    </a>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">English/USD</a></li>
                    </ul>
                  </li>
                  <li className="nav-item d-none">
                    <a className="nav-link" href="#">Find Tutors</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={`/all-courses`}>All Courses</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={`/teach`}>Become a Tutors</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={`/login`}>Login</Link>
                  </li>
                  <li className="nav-item">
                    <Link className=" btn btn-outline-mainColor" to={`/signup-student`}>Sign up</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>


    </>
  )
}