import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CoursePlaceholder from "../CustomHooks/CoursePlaceholder";
import CourseCard from "./CourseCard";

export default function HomeCourse() {
  const categories = useSelector((state) => state.categoryReducer);
  const select_home = useSelector((state) => state.homeReducer);

  return (
    <>
      <div className="course-are course-padding">
        <div className="container">

          <div className="section-title text-center">
            <h2 className="title">All Courses at a Glance</h2>
          </div>

          <div className="courses-wrapper">
            <div className="courses-menu-area">
              <div className="courses-menu-button">
                <ul className="nav justify-content-center">
                  {categories?.data?.length > 0 && categories?.data?.slice(0, 6).map((item, i) => (
                    <li key={i}><Link to={`/category/${item.slug}`} className={`courses-menu-btn btn rounded-pill bg-white `}>{item.name}</Link></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="courses-class-content">
              <div className="row">

                {select_home?.loading && select_home?.course?.map(course => (
                  <CoursePlaceholder />
                ))}

                {!select_home?.loading && select_home?.course?.length > 0 && select_home?.course?.slice(0, 8).map((course, j) => (
                  <div className="col-lg-3 col-sm-6 mb-3" key={j}>
                    <CourseCard course={course} />
                  </div>
                ))}

              </div>
            </div>
          </div>
          {/* Courses Wrapper End */}
        </div>
      </div>
    </>
  )
}