import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import CoursePlaceholder from "../CustomHooks/CoursePlaceholder";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { COURSE_FETCH_ERROR, COURSE_FETCH_START, COURSE_FETCH_SUCCESS, COURSE_TOTAL_PAGE } from "../Reducer/courseSlice";
import API from "../Network/API";
import CourseCard from "../components/CourseCard";

export default function AllCourse() {
  const categories = useSelector((state) => state.categoryReducer);
  const select_course = useSelector((state) => state.courseReducer);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');


  const dispatch = useDispatch();
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
   
    

    const fetchCourses = () => {
      dispatch(COURSE_FETCH_START())
      const pageQuery = currentPage > 0 ? `&page=${currentPage + 1}` : '';
      const q=keyword==null?'':keyword;
      API.get(`/v1/oauth/all_course?keyword=${q}${pageQuery}`)
        .then((response) => {
          dispatch(COURSE_FETCH_SUCCESS(response.data.data));
          dispatch(COURSE_TOTAL_PAGE(response.data.data.total_page));
        })
        .catch((error) => {
          dispatch(COURSE_FETCH_ERROR());
        });
    };

    fetchCourses();

  }, [currentPage,keyword]);

  


  return (
    <>
      <div className="course-are course-padding course-page">
        <div className="container">

          <div className="section-title text-center">
            <h2 className="title">All Courses at a Glance</h2>
          </div>

          <div className="courses-wrapper">
            <div className="courses-menu-area">
              <div className="courses-menu-button">
                <ul className="nav justify-content-center">
                  {categories?.data?.length > 0 && categories?.data?.slice(0, 6).map((item, i) => (
                    <li><Link to={`/category/${item.slug}`} className={`courses-menu-btn btn rounded-pill bg-white `}>{item.name}</Link></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="courses-class-content">
              <div className="row">
                {select_course?.loading && select_course?.courses?.map(course => (
                  <CoursePlaceholder />
                ))}
                {!select_course?.loading && select_course?.courses?.length > 0 && select_course?.courses?.map((course, j) => (
                  <div className="col-lg-3 col-sm-6" key={j}>
                    <CourseCard course={course} />
                  </div>
                ))}
              </div>

              <div className="mt-3 ms-3">
                <ReactPaginate
                  pageCount={select_course?.total_page}
                  onPageChange={handlePageChange}
                  forcePage={currentPage}
                  containerClassName={'pagination'}
                  previousLinkClassName={'page-link'}
                  breakClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageClassName={'page-item'}
                  pageLinkClassName="page-link"
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}