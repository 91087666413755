import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom";

export default function Banner() {
    const [keyword, setKeyWord] = useState('');
    const selector = useSelector((state) => state.appearanceReducer);
    const appearanceData = selector?.data;
    const navigate=useNavigate()

    const search_course = () => {
        navigate('/all-courses?keyword='+keyword);
    }

    return (
        <>
            <div className="banner-area position-relative">
                <div className="container">
                    <div className="main-max-width">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner-content position-relative">
                                    <div className="banner-content-title">
                                        <h1>{appearanceData.header_title ? appearanceData.header_title : 'Empowering You to Learn, Grow, and Succeed'}</h1>
                                    </div>
                                    <div className="banner-content-dec">
                                        <p>{appearanceData.header_sub_title ? appearanceData.header_sub_title : 'Join a thriving community of global learners. Support each other, share progress, and celebrate every milestone along your learning path.'}</p>
                                    </div>
                                    <div className="banner-content-search d-flex">
                                        <div className="flex-grow-1 banner-content-search-inputBox me-3 position-relative">
                                            <input onKeyUp={(e)=>setKeyWord(e.target.value)} type="text" className="form-control form-control-lg banner-form-input" placeholder="What do you want to learn?" />
                                            <div className="banner-content-search-icon position-absolute">
                                                <i className="fa-light fa-search" />
                                            </div>
                                        </div>
                                        <button type="button" onClick={()=>search_course()} className="flex-shrink-0 btn btn-lg rounded getStart-btn">Get Started</button>
                                    </div>
                                    <Link to="/all-courses" className="text-decoration-underline Explore-link">
                                        Explore Courses
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-image position-relative">
                                    <img className="img-fluid" src="assets/img/xller-banner.png" alt="shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}