import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGGED_IN } from "../../Reducer/authSlice";
import { useEffect, useState } from "react";
import API from "../../Network/API";
import { MY_COURSE_FETCH_START, MY_COURSE_FETCH_SUCCESS, MY_COURSE_TOTAL_PAGE } from "../../Reducer/myCourseSlice";
import { CHAT_LIST_FETCH_SUCCESS } from "../../Reducer/chatListSlice";
import { UPCOMING_ENROLLMENT_FETCH_SUCCESS } from "../../Reducer/upcomingEnrollmentSlice";

export default function TutorNav() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const _logout = () => {
        setLoading(false);
        API.post(`/v1/oauth/logout`)
            .then(res => {
                setLoading(false);
                if (res.data.success === true) {
                    localStorage.clear();
                }
            })
        localStorage.clear();
        window.location.href = '/';
    }

    useEffect(() => {
    
        dispatch(MY_COURSE_FETCH_START())
        API.get(`/v1/oauth/teacher/mycourses`).then((response) => {
          dispatch(MY_COURSE_FETCH_SUCCESS(response.data.data))
          dispatch(MY_COURSE_TOTAL_PAGE(response.data.data.total_page))
        }).catch((error) => {
        });
    
        API.get(`/v1/oauth/chatlist`).then((response) => {
            dispatch(CHAT_LIST_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });

        API.get(`/v1/oauth/teacher/upcoming_course`).then((response) => {
            dispatch(UPCOMING_ENROLLMENT_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });
    
      }, []);
  
    return (
        <>
            <div className="stickyPanelSidebar">
                <div className="settings-widget dash-profile">
                    <div className="settings-menu">
                        <div className="profile-bg">
                            <div className="profile-img">
                                <a href="#"><img src="/assets/img/user-default.png" alt="Img" /></a>
                            </div>
                        </div>
                        <div className="profile-group">
                            <div className="profile-name text-center">
                                <h4><a href="#">{user?.name}</a></h4>
                                <p>{user?.role == 'teacher' ? "Teacher" : "Student"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="settings-widget account-settings">
                    <div className="settings-menu">
                        <h3>Dashboard</h3>
                        <ul>
                            <li className="nav-item active">
                                <Link to={`/dashboard/tutor`} className="nav-link">
                                    <i className="fa-light fa-grid-2" />Dashboard
                                </Link>
                            </li>
                            <li className="nav-item ">
                            <Link to={`/dashboard/chat`} className="nav-link">
                                    <i className="fa-light fa-message-lines" />Messages
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/dashboard/my-courses`} className="nav-link">
                                    <i className="fa-light fa-chalkboard-user" />Courses
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fa-light fa-user-graduate" />Saved tutors
                                </a>
                            </li>
                        </ul>
                        <h3>Account Settings</h3>
                        <ul>
                            <li className="nav-item">
                                <a href="#" className="nav-link ">
                                    <i className="fa-light fa-user-gear" />Settings
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link ">
                                    <i className="fa-light fa-circle-info" />Help
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link" onClick={() => _logout()}>
                                    <i className="fa-light fa-power-off" />Logout{isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}